<template>
<div>
  <div class="box">
    <div class="nav-box">
      <div class="setting" style="margin-left: 25px;">
        <a-button @click="toQQ" type="primary" shape="circle" icon="qq" size="large" />
      </div>
      <div class="title">欢迎来到9128服务器</div>
      <div class="admin-button" style="margin-right: 25px;">
        <a-button @click="adminShow" type="danger" shape="circle" icon="usergroup-delete" size="large" />
      </div>
    </div>
    <div class="card-box-use">
      <Card v-for="(item,index) in $store.state.dataContent" :key="index" :data="item"></Card>
    </div>
    <div class="card-box-use-table">
       <a-table :pagination="false" :columns="columns" :data-source="data">
          <a slot="name" slot-scope="text">{{ text }}</a>
       </a-table>
    </div>
  </div>
  <a-modal
      title="请输入管理员密码"
      :visible="modalShow"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-input v-model="adminPassword" placeholder="让老子看看你是不是服主"></a-input>
    </a-modal>
</div>
</template>

<script>
import Card from '../components/Card'
export default {
  name: 'Home',
  components:{
    Card
  },
  data(){
    return{
      modalShow:false,
      modelAdmin:false,
      adminPassword:'',
      columns:[
        {
         title:'内鬼名称',
         dataIndex:'name',
         width:'520px' 
        },
        {
         title:"内鬼事迹",
         dataIndex:'shiji',
         width:'820px'
        }
      ],
      data:[
        {
          name:'x********g',
          shiji:"女王杀手"
        },
        {
          name:'y********y',
          shiji:"飞升途中'误伤'队友"
        }
      ]
    }
  },
  methods:{
    handleOk(e){
      if(this.adminPassword !== '我就是服主'){
        this.$message.error('密码错误');
        this.modalShow = false
      } else {
        this.$message.success('密码正确');
        this.modalShow = false
      }
    },
    handleCancel(){
      this.modalShow = false
    },
    adminShow(){
      this.modalShow = true
    },
    toQQ(){
      window.open('https://qm.qq.com/cgi-bin/qm/qr?k=BS0N27qiFkvomnyBRwNImHLYx2mjGbY1&jump_from=webapi')
    }
  }
}
</script>
<style scoped>
    .box{
      /* 这个是页头 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-image: url('../static/backc.jpg');
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
    }
    .nav-box{
      width: 60%;
      height: 90px;
      background-color: rgba(255, 255, 255, 0.9);
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title{
      font-size: 25px;
      font-family: '华文中宋';
    }
    .card-box-use{
      width: 70%;
      height: auto;
      margin-top: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card-box-use-table{
      width: 70%;
      height: auto;
      margin-top: 30px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card-box-use:after {
      content: "";
      width: 50%;
      height: 0;
      visibility: hidden;
    }
</style>
