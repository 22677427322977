import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl:'steam://connect/150.138.72.39:',
    dataContent:[
      {
        name:'孤岛',
        port:'11191',
        descript:'最原始的地图'
      },
      {
        name:'仙境',
        port:'11201',
        descript:'雪山很冷，多穿衣服哦'
      },
      {
        name:'畸变',
        port:'11206',
        descript:'地下世界!'
      },
      {
        name:'灭绝',
        port:'11231',
        descript:'给君王两个大逼兜'
      },
      {
        name:'瓦尔盖罗',
        port:'11256',
        descript:'恐爪龙特色？'
      },
      {
        name:'创一',
        port:'11216',
        descript:'我叫HLN-A'
      },
      {
        name:'创二',
        port:'11221',
        descript:'高科技地图！'
      },
      {
        name:'水晶岛',
        port:'11196',
        descript:'打打水晶？'
      },
      {
        name:'中心岛',
        port:'11261',
        descript:'5A级方舟南巨自然保护区'
      },
      {
        name:'迷失岛',
        port:'11226',
        descript:'新地图！大眼翼龙yyds'
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
