<template>
<div class="card-box">
  <a-card @click="addServer" hoverable style="width: 300px">
    <a-card-meta  :description="data.descript">
      <div class="cardText" slot="title">{{ data.name }}</div>
    </a-card-meta>
  </a-card>
</div>
</template>

<script>
export default {
  name: "Card",
  props:['data'],
  data(){
    return{

    }
  },
  methods:{
    addServer(){
      this.$notification.open({
        message: '欢迎大佬加入服务器'+this.data.name,
        description: '大佬又在肝啊，小心身体哟',
        icon: <a-icon type="smile" style="color: #108ee9" />,
      });
      window.location.href=this.$store.state.baseUrl + this.data.port
    }
  }
}
</script>

<style scoped lang="scss">
@mixin wh($w,$h){
  width: $w;
  height: $h;
}
.card-box{
  @include wh(auto,auto);
 margin: 10px;
  .cardText{
    font-size: 40px;
    font-family: '华文中宋',serif;
  }

}
</style>
